import React,{useRef} from 'react'
import * as popular_destination_action from "../../actions/popularDestinationAction";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import PlusIcon from "../../assets/images/plusIconSquare.svg";
import ImageCropper from '../utilities/ImageCropper';


const AddImage = ({ altText, setAltText, imageTitle, setImageTitle}) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const fileRef = useRef();
    const getMediaUrl = (file) => {
       
        if(file){
          
            let mediaExtension;
    
            if (file) {
                mediaExtension = file?.type
                    ?.split("/")
                    .pop()
                    .toLowerCase();
            }
   
            if (
                mediaExtension == "mp4" ||
                mediaExtension == "jpeg" ||
                mediaExtension == "jpg" ||
                mediaExtension == "png"||
                mediaExtension == "webp"

            ) {

                dispatch(popular_destination_action.mediaUrl(file));

            } 
            else {
                enqueueSnackbar(
                    "Please upload mp4/jpeg/jpg/png/webp format media only",
                    {
                        variant: "error",
                    }
                );
            }

        }
   
    };
    
  return (
  <>
     <div className="add-image-button">
                    {/* <label for="file-input">
                        <span> Add Image</span>
                    
                    </label> */}
                   
                    {/* <img
                        src={PlusIcon}
                        alt="uploadIcon"
                        style={{ marginLeft: "90px", marginTop: "9px" }}
                    /> */}


                <ImageCropper
                        fileRef={fileRef}
                        onChange={getMediaUrl}
                        cropOptions={{ aspect: 3 / 3, maxZoom: 10 }}
                        outputOptions={{ maxWidth: 250, maxHeight: 250 }}
                        previewOptions={{ width: 250, height: 250 }}
                        buttonStyle={{
                        width: 90,
                        height: 20,
                        backgroundColor: "white",
                        fontSize: 12,
                        textTransform: "capitalize",
                        }}
                        isAddImagePopular={true}
                        altText={altText} 
                        setAltText={setAltText} 
                        imageTitle={imageTitle}
                        setImageTitle={setImageTitle}
                />

                    {/* <input
                        id="file-input"
                        type="file"
                        // hidden
                        onChange={(e) => getMediaUrl(e.target.files)}
                    /> */}

                </div>
  </>
  )
}

export default AddImage