import React, { useEffect, useState } from "react";
import PlusIcon from "../../../src/assets/images/plusIconSquare.svg";
import deleteIcon from "../../../src/assets/images/deleteItinerary.png"
import CkEditor from "../../common/ckEditor/ckeditor";
import JoditEditor from "jodit-react";






const ItinraryBoxField = ({currentPackage,setState}) => {
  const[fieldData,setFieldData]=useState([{
    
    title:"",
    description:"",
  }])
  console.log(fieldData,"fieldData");
  
  const handleaddfield=()=>{
    setFieldData([...fieldData,{
      title:"",
      description:""}])
  }
  const handleFieldChange = (index, key, value) => {
    
    const updatedFields = fieldData.map((item, i) =>
      i === index ? { ...item, [key]: value } : item
    );
    setFieldData(updatedFields);
  };

  const handledelete=(index)=>{
    const deleteandupdate=fieldData.filter((elem,indx)=>{
        return index!==indx
    })
    setFieldData(deleteandupdate)


  }
  
  useEffect(() => {
    const formattedItinerary = fieldData?.reduce((acc, elem, indx) => {
      acc[`day_${indx + 1}`] = {
        title: elem.title,
        content: [
          {
            description: elem.description,
          },
        ],
      };
      return acc;
    }, {});
  
    setState((prevState) => ({
      ...prevState,
      itinerary: JSON.stringify(formattedItinerary), // Store as plain object
    }));
  }, [fieldData]);

  useEffect(()=>{
    console.log(currentPackage?.itinerary,"itinerary");
    if(typeof currentPackage?.itinerary==="string"){
      const result = Object.entries(JSON.parse(currentPackage?.itinerary)).map(([key, value]) => ({
        title: value.title,
        description: value.content[0].description
      }));
      setFieldData(result)
     
      

    }
  },[currentPackage?.itinerary])
  

    return (
        <div style={{ padding: "12px" }}>
            
            <p style={{fontWeight:"600"}}>Itinerary days</p>
            

            {fieldData?.map((elem,indx)=>{
              return(
                <>
             <div
                style={{
                    backgroundColor: "white",
                    height: "auto",
                    margin: "25px 0px",
                    borderRadius: "12px",
                    padding: "10px",
                }}
            >
                   <div style={{display:"flex",justifyContent:"space-between"}}>
            <p style={{marginLeft:"0"}}> Day {indx+1}</p>
            <img
                    src={deleteIcon}
                    alt="addIcon"
                    style={{height:"30px",width:"30px"}}
                    onClick={()=>handledelete(indx)}
                   
                />
            </div>
            <p style={{marginBottom:"2px"}}>Title</p>
                <input
                    type="text"
                    
                    style={{
                        width: "100%",
                        height: "35px",
                        backgroundColor: "#F4F4F4",
                        borderRadius: "6px",
                        padding: "9px",
                        border: "none",
                        marginBottom:"10px"
                    }}
                    value={elem.title}
                    onChange={(e) => handleFieldChange(indx, "title", e.target.value)}
                ></input>
              
                {/* <textarea
                    type="text"
                    placeholder="Description"
                    style={{
                        width: "100%",
                        height: "100px",
                        backgroundColor: "#F4F4F4",
                        borderRadius: "6px",
                        padding: "9px",
                        border: "none",
                        margin: "15px 0px 0px 0px",
                        resize: "none",
                    }}
                    value={elem.description}
                    onChange={(e) => handleFieldChange(indx, "description", e.target.value)}
                ></textarea> */}
                <p style={{marginBottom:"2px"}}>Description</p>
              <CkEditor
                      data={elem.description}
                      handleChange={(newContent) => { handleFieldChange(indx, "description", newContent)}}
                 
                     
                    
               />
               
            </div>
                </>
              )
      
            })}

<div
                onClick={handleaddfield}
                style={{
                    position: "relative",
                    marginTop: "0px",
                    marginBottom: "0px",
                    backgroundColor: "white",
                    width: "110px",
                    height: "38px",
                    border: "1px solid black",
                    padding: "8px",
                    cursor: "pointer",
                    fontSize: "15px",
                }}
            >
                Add Days
                <img
                    src={PlusIcon}
                    alt="addIcon"
                    style={{
                        position: "absolute",
                        left: "80%",
                        top: "12px",
                        height: "12px",
                    }}
                />
            </div>
        </div>
    );
};

export default ItinraryBoxField;
