import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import React, {Component} from 'react';

class App extends Component {
    editor = null;
    constructor(props){
        console.log("itenrary props:", props);
        super(props)
        
    }

    render() {
        return (
            <div className="ck_editor_new">
                <CKEditor
                    onReady={ editor => {
                        // Insert the toolbar before the editable area.
                        editor.ui.getEditableElement().parentElement.insertBefore(
                            editor.ui.view.toolbar.element,
                            editor.ui.getEditableElement()
                        );

                        this.editor = editor;
                    } }
                    onError={ ( error, { willEditorRestart } ) => {
                        // If the editor is restarted, the toolbar element will be created once again.
                        // The `onReady` callback will be called again and the new toolbar will be added.
                        // This is why you need to remove the older toolbar.
                        if ( willEditorRestart ) {
                            this.editor.ui.view.toolbar.element.remove();
                        }
                    } }
                    onChange={ ( event, editor ) => this.props.handleChange(editor.getData()) }
                    editor={ DecoupledEditor }
                    data={this.props.data}
                />
                </div>
        );
    }
}

export default App;
