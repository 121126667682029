import React from 'react'

const Input = ({handleChange, value, label, key, isDivider, disableChar,disabled,maxLimit,mandatory}) => {
  return (
        <div>
           
            {mandatory==false?"":label=="2nd Location (optional)"?"":<span style={{color:"red"}}> * </span> }<label for="">  {label} {maxLimit && `(${value?.length || 0}/${maxLimit})`}</label>
            {isDivider ? <div className='divider'/> : ""}
            <input
            value={value}
            type="text"
            disabled={disabled}
            maxLength={maxLimit ? maxLimit : undefined}
            className="form-control"
            onChange={(e) => handleChange(e)}
            onKeyPress={disableChar ? (event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }: null}
            />
            
        </div>
  )
}

export default Input